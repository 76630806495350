import { ApiService } from 'react-api-service-layer';
import axios from 'axios';
import * as API_CONSTANTS from '../utilities/Constants_AKSURL';

interface MethodOptions {
    //  If you dont want to build the path from the baseUrl and just use the path provided
    excludeBaseUrl?: boolean;
}

export class AxiosApiService extends ApiService {
    private baseUrl: string;

    constructor() {
        super();
        this.baseUrl = API_CONSTANTS.APP_BASE_URL;
    }

    get(resourcePath: string, options?: MethodOptions): Promise<any> {
        return axios.get(`${options?.excludeBaseUrl ? '' : this.baseUrl}${resourcePath}`).then(r => r.data);
    }
    post(resourcePath: string, payload: any, options?: any): Promise<any> {
        throw new Error('Method not implemented.');
    }
    put(resourcePath: string, payload: any, options?: any): Promise<any> {
        throw new Error('Method not implemented.');
    }
    patch(resourcePath: string, payload: any, options?: any): Promise<any> {
        throw new Error('Method not implemented.');
    }
    delete(resourcePath: string, options?: any): Promise<any> {
        throw new Error('Method not implemented.');
    }

}