import BreadCrumb from '../../../components/BreadCrumb';
import { useUrlParams } from '../hooks/use-url-params';
import { LABELS } from '../constants/labels';
import { ROUTES} from '../constants/routes';

//  Smart component to handle bread crumbs
export const BreadCrumbs = () => {
    const titles = [LABELS.BREAD_CRUMB];
    const paths = [];
    const urlParams = useUrlParams();

    if (urlParams.labResultId) {
        //  We must be in a detail result, so add that here
        titles.push(urlParams.labResultId.toString());
        paths.push(ROUTES.LIST);
    }

    return (
        <BreadCrumb titles={titles} paths={paths} />
    );
};
